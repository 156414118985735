<template>
    <div class="zixun">
        <div class="zixun_body">
                <el-row>
                        <el-col :span="12" class="p-2" style="width: 50%">
                            <el-card shadow="always">
                                <div class="card">
                                    <div class="card-title" style="width: 20%;
                                        height: 2.5625rem;
                                        background-color: #E34545;
                                        color: #fbfbfa;
                                        text-align: center;
                                        padding-top: .5625rem;
                                        font-size: .9375rem;
                                        border-radius: .25rem;
                                        font-weight: 400;
                                       ">最新资讯
                                    </div>
                                    <div class="account-body p-3">
                                        <div class="account-main">
                                            <span class="mr-3" style="float: right;font-size: .75rem;ursor: pointer;"
                                                  @click="getXQlist"><a>更多</a></span>
                                        </div>
                                        <div class="card-message" v-if="index <5" v-for="(item,index) in zixunList" :key="index">
                                            <div class="card-message_t card_size">{{item.newsreleasename}}</div>
                                            <p class="card-message_b card_size" v-html="item.content.substr(0,20)" ></p>
                                            <div @click="gotXQDetails(item.newsreleasename,item.url,'info')" class="cursors" align="right">
                                                <!-- <a class="a-link" style="font-size: .8125rem;color: #8d8e8f;">[详细]</a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="12" class="p-2" style="width: 50%">
                            <el-card shadow="always">
                                    <div class="card">
                                        <div class="card-title" style="width: 20%;
                                        height: 2.5625rem;
                                        background-color: #E34545;
                                        color: #fbfbfa;
                                        text-align: center;
                                        padding-top: .5625rem;
                                        font-size: .9375rem;
                                        border-radius: .25rem;
                                        font-weight: 400;
                                       ">承诺函
                                        </div>
                                        <div class="account-body p-3">
                                            <div  class="account-main">
                                                   <span class="mr-3" style="float: right;font-size: .75rem;cursor: pointer;"
                                                         @click="getNoticelist('Bidding')"><a>更多</a></span>

                                                <div class="card-message" v-if="index <5" v-for="(item,index) in dataBiddinglist" :key="index">
                                                    <div class="card-message_t card_size">标的物名称：{{item.targetname}}</div>
                                                    <div class="card-message_b card_size">开始时间：{{item.begintime}} 、结束时间{{ item.endtime}}</div>
                                                    <div @click="gotNoticeDetails(item.targetname,'public',item.targetid)" class="cursors" align="right">
                                                        <!-- <a class="a-link"  style="font-size: .8125rem;color: #8d8e8f;">[详细]</a> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </el-card>
                        </el-col>
                </el-row>
            <el-row>
                <el-col :span="12" class="p-2" style="width: 50%">
                    <el-card shadow="always">
                        <div class="card">
                            <div class="card-title" style="width: 20%;
                                        height: 2.5625rem;
                                        background-color: #E34545;
                                        color: #fbfbfa;
                                        text-align: center;
                                        padding-top: .5625rem;
                                        font-size: .9375rem;
                                        border-radius: .25rem;
                                        font-weight: 400;
                                       ">招商公告
                            </div>
                            <div class="account-body p-3">
                                <div class="account-main">
                                            <span class="mr-3" style="float: right;font-size: .75rem;ursor: pointer;"
                                                  @click="getZhaoshanglist"><a>更多</a></span>
                                </div>
                                <div class="card-message" v-if="index <5" v-for="(item,index) in zhaoShangList" :key="index">
                                    <div class="card-message_t card_size">{{item.goodsname}}</div>
                                    <div class="card-message_b card_size">项目编号：{{item.projectno}} ，发布时间：{{item.releasetime}} ，项目概况：{{item.project}}</div>
<!--                                    <div @click="gotXQDetails(item.newsReleaseName,item.url,'info')" class="cursors" align="right">-->
<!--                                        <a class="a-link" style="font-size: .8125rem;color: #8d8e8f;">[详细]</a>-->
<!--                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="12" class="p-2" style="width: 50%">
                    <el-card shadow="always">
                        <div class="card">
                            <div class="card-title" style="width: 20%;
                                        height: 2.5625rem;
                                        background-color: #E34545;
                                        color: #fbfbfa;
                                        text-align: center;
                                        padding-top: .5625rem;
                                        font-size: .9375rem;
                                        border-radius: .25rem;
                                        font-weight: 400;
                                       ">更多...
                            </div>
                            <div class="account-body p-3">
                                <!-- <div  class="account-main">
                                                   <span class="mr-3" style="float: right;font-size: .75rem;cursor: pointer;"
                                                         @click="getNoticelist('Bidding')"><a>更多</a></span>

                                    <div class="card-message" v-if="index <5" v-for="(item,index) in dataBiddinglist" :key="index">
                                        <div class="card-message_t card_size">【{{item.begintime}}】竞价场次：{{item.bidname}}</div>
                                        <div class="card-message_b card_size">竞价物资：{{item.productname}} 、结束时间{{ item.endtime}}</div>
                                        <div @click="gotNoticeDetails(item.bidname,'public',item.targetid)" class="cursors" align="right">
                                            <a class="a-link"  style="font-size: .8125rem;color: #8d8e8f;">[详细]</a>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>

    export default {
        name: "zixun",
        created() {
            this.activeName = '3';
        },
        data() {
            return {
                zixunList: [],
                // Noticelist: [],
                zhaoShangList: [],
                activeName: '3',
                //分页数据
                dataForm: {
                    productid: '',
                    delivery: '',
                    outbidstate: '',
                    splitstate: ''
                },
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                bidnames: '',//竞价场次名称
                timeDate: '',//开始结束时间
                dataBiddinglist: [],
////////////////////////////////////////////////////////
                //竞买公告
                tableData: '',
                //煤种
                coal: '',
                coalList: [],
                //采购单位
                purchasingUnit: '',
                purchasingUnitList: [],


            }
        },

        mounted() {
            // this.getList1();
            this.getNewsDataList();
            this.getDataList()
            this.getZhaoshangDataList()

        },
        methods: {

            // 新闻资讯
            getNewsDataList() {
            this.$fetch('/shoppingui/bidShopping/getNewReleasePage', {
                'page': this.pageIndex,
                'limit': this.pageSize,
            }, true).then((data) => {
                if (data && data.code === 0) {
                     this.zixunList = data.data.list;
                } else {
                this.zixunList = []
                this.$message({
                    message: data.msg + '获取失败',
                    type: 'error',
                    showClose: 'true'
                })
                }
            })
            },

            //转换时间格式
            formateDate(datetime) {
                if(datetime == '' || datetime == undefined) {
                    return '';
                }
                function addDateZero(num) {
                    return (num < 10 ? "0" + num : num);
                }
                let d = new Date(datetime);
                let formatdatetime = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d.getDate()) ;
                return formatdatetime;
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            //获取咨询列表
            getList1() {
                this.$fetch('exnewsrelease/list', {
                    'parameterB': 1
                }, true).then((data) => {
                    if (data && data.code === 0) {
                        //列表数据
                        this.zixunList = data.page.list;
                        //分页
                        this.totalPage = data.page.totalCount
                    } else {
                        this.zixunList = []
                        this.totalPage = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
           


            //获取竞买列表信息
            getList() {
                if (this.activeName != '2') {
                    this.$fetch('tptargetinfo/list', {
                        'page': this.pageIndex,
                        'limit': this.pageSize,
                        'outbidstate': '',
                        'pproductid': this.coal,
                        'companyname': this.purchasingUnit,
                        'begintime': this.timeDate === ''|| this.timeDate === null ? '' :this.formateDate(this.timeDate[0]),
                        'endtime': this.timeDate === '' || this.timeDate === null ? '':this.formateDate(this.timeDate[1])
                    }, true).then((data) => {
                        if (data && data.code === 0) {
                            this.tableData = data.page.list;
                            this.totalPage = data.page.totalCount;
                        } else {
                            this.$message({
                                message: data.msg,
                                type: 'error',
                                showClose: 'true'
                            })
                        }
                    })
                } else {
                    this.$fetch('tptargetinfo/endList', {
                        'page': this.pageIndex,
                        'limit': this.pageSize,
                        'pproductid': this.coal,
                        'companyname': this.purchasingUnit,
                        'begintime': this.timeDate === ''|| this.timeDate === null ? '' :this.formateDate(this.timeDate[0]),
                        'endtime': this.timeDate === '' || this.timeDate === null ? '':this.formateDate(this.timeDate[1])
                    }, true).then((data) => {
                        if (data && data.code === 0) {
                            this.tableData = data.page.list;
                            this.totalPage = data.page.totalCount;
                        } else {
                            this.$message({
                                message: data.msg,
                                type: 'error',
                                showClose: 'true'
                            })
                        }
                    })
                }

            },

            getZhaoshangDataList() {
                this.$fetch('/shoppingui/memberEntShopping/pageList', {
                    'page': this.pageIndex,
                    'limit': this.pageSize,
                    'pproductid': this.coal,
                    'companyname': this.purchasingUnit,
                    'begintime': this.timeDate === ''|| this.timeDate === null ? '' :this.formateDate(this.timeDate[0]),
                    'endtime': this.timeDate === '' || this.timeDate === null ? '':this.formateDate(this.timeDate[1])
                }, true).then((data) => {
                    if (data && data.code === 0) {
                        this.zhaoShangList = data.data.list;
                        this.totalPage = data.data.total;
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })
            },
                // 获取 承诺函数据列表
            getDataList() {
            this.$fetch('/shoppingui/bidShopping/targetPageList', {
                'page': this.pageIndex,
                'limit': this.pageSize,
                'order': 'asc',
                // 'delivery': this.dataForm.delivery,
                // 'outbidstate': this.dataForm.outbidstate,
                // 'splitstate': this.dataForm.splitstate,
                // 'begintime': this.timeDate === ''|| this.timeDate === null ? '' :this.formateDate(this.timeDate[0]) +' ' + '00:00:00',
                // 'endtime': this.timeDate === '' || this.timeDate === null ? '':this.formateDate(this.timeDate[1]) + ' ' + '23:59:59',
                // 'bidname': this.bidnames === '' || this.bidnames === null? '':this.bidnames,
            }, true).then((data) => {
                if (data && data.code === 0) {
                     this.dataBiddinglist = data.data.list;
                        this.bidtotalPage = data.data.totalCount
                // this.deptlist= data.data.list

                } else {
                this.dataBiddinglist = []
                this.bidtotalPage = 0
                this.$message({
                    message: data.msg + '获取失败',
                    type: 'error',
                    showClose: 'true'
                })
                }
            })
            },


          //资讯
            getXQlist() {
                 let queryData = {};
                this.$router.push({path: "/XiangQingList", query: queryData});
            },
            //公告
            getNoticelist(modes) {
                let queryData = {};
                queryData.modes = modes;
                this.$router.push({path: "/NoticeList", query: queryData});
            },
            getZhaoshanglist() {
                let queryData = {};
                this.$router.push({path: "/ZhaoshangList", query: queryData});
            },

            gotXQDetails(name, url,title) {
                let queryData = {};
                queryData.url = url;
                queryData.newsReleaseName = name;
                queryData.title = title;
                // this.$router.push({path: "/zxXiangQing", query: queryData});
            },
            // 承诺函
            gotNoticeDetails(name,title,targetid) {
                let queryData = {};
                queryData.bidname = name;
                queryData.title = title;
                queryData.targetid = targetid;

                // this.$router.push({path: "/zxXiangQing", query: queryData});
            },
            //公告竞买
            gotNoticeProduct(name,title,ptargetid) {
                let queryData = {};
                queryData.bidname = name;
                queryData.title = title;
                queryData.ptargetid = ptargetid;

                this.$router.push({path: "/ProductXQ", query: queryData});
            },
        }
    }
</script>

<style lang="scss" scoped>
    /*@import "../assets/css/index.css";*/
    @import "../assets/css/page/zixun.scss";
</style>
